@import '../base/colors';
.header  // JS 11.07.20
{
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;

}
/*----- Logo -----*/
.brand {
    float: left;
    padding: 0.5em 1em 0 0.5em;
  }
  
  .brand .logo {
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    transition: .5s all ease;
    width: 186px; // js: 246px;

    @media (max-width: 480px) {
      width: 102px;
      margin: 2px 0;
    }
    &:hover {
      transform: scale(1.1);
      font-size:50px;
    }   
  }


  

/*-----CSS: Menü----------- unused wegen href im Link!!!!*/
// https://a-vista-studios.de/blog/webdesign/mobile-menue-ohne-javascript/
  nav {
    margin: 0 auto;
    width: 100%;
    height: 7em; //js 11em;
    text-align: right;
    position: relative;
    //background: rgba(255, 255, 255, 1.0); JS: raus  01.06.20
    border-bottom: 1px solid white;
    @media (max-width: 960px) {
        height: 5em;
      }
    @media (max-width: 480px) {
        height: 3em;
      }
  }
   
  nav ul {
    margin: 0;
    padding: 5.1em 0 0 0;  //Js  8.1em 0 0 0;
    height: 100vh;
  }
   
  nav ul li {
    list-style: none;
    margin: 0;
    display: inline;
  }
   
  nav ul li a {
    color: #009999;
    position: relative;
    //padding: 0 0 0.5em 0; //JS: 10.01.20 - Verhindert Überlappung des noch vorhandenen doppelten Unterstrich
    display: inline-block;
    text-decoration: none;
    // text-transform: uppercase; // JS: 11.01.20
  }
   
  nav ul.menu li a:hover {
    color: white;   /* JS: geämder  01.06.20 */
    border-bottom: 2px solid #03405f;
  }

/*----Media Queries------------*/

@media screen and (min-width: 961px) {
    nav ul.menu {
      max-height: 20px;
    }
    nav a#nav-m,
    nav a.close {
      display: none;
    }
    nav ul li {
      margin: 0 30px 0 0;
    }
  }
   
   
  @media screen and (max-width: 960px) {
    /*Formatierung Mobiles Menue*/
    nav ul {
      z-index: 98;  // js 17.05.19
      top: 12em;
      position: absolute;
      background: #ccc;
      width: 100%;
      left: -101%; // js Srew: -100
   
      list-style-type:none;
      margin: 0;
      padding: 0;
   
      -webkit-transition: all 0.6s ease;
      -moz-transition: all 0.6s ease;
      -o-transition: all 0.6s ease;
      transition: all 0.6s ease;
    }
   
    nav ul li{
      display: block;
      text-align: left;
      padding: 0em 0.5em;
      border-bottom: 1px solid #fff;
    }
   
    nav ul li a {
     
      color: #555;
      padding: 1em 0;
    }
   
    nav ul.menu li a:hover {
      border-bottom: 0;
    }
   
    /*Hamburger-Icon*/
    nav a#nav-m img,
    nav a.close img {
      width: 40px;
      height: auto;
    }
   
    nav > .close,
    nav a#nav-m:target {
      display: none;
    }
   
    nav a:target ~ ul,
    nav a:target ~ .close {
      display: inline-block;
    }
   
    nav a:target ~ .close {
      position: absolute;
      margin-left: -40px;
    }
   
    nav a:target ~ ul {
      left: 0;
    }
  }
  /*--------------------------------------*/
  /* Menü bar -------------------------------*/
    
  // https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_menu_icon_js
  .barcontainer {  
    
    display: inline-block;
    cursor: pointer;
    padding: 0.2em 0.2em 0.2em 0.2em;
    &.zu {      
      padding: 0.2em 0.2em 0.2em 0.0em; 
    }
    
  }  
  .bar1, .bar2, .bar3 {  
    width: 35px;
    height: 5px;
    background-color:white;
    margin: 6px 0;
    transition: 0.4s;  
    &.zu {      
      background-color:$primary-orange;
    }
  }  
  /*
  https://www.w3schools.com/howto/howto_css_menu_icon.asp
  <div className='container'>
              <div class="bar1"></div>
              <div class="bar2"></div>
              <div class="bar3"></div>
  </div>
  */
  // https://sass-lang.com/guide
  @mixin transform($property) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property;
  }
    
  .croix{    
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    overflow: hidden;
    &:hover {
      &::before, &::after {
        background: $primary-orange;
      }
    }
  
    &::before, &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: $white;
    }
    &::before {
      @include transform(rotate(45deg));
    }
    &::after {
      @include transform(rotate(-45deg));
    }
    &.big {
      @include transform(scale(3));
    }
    &.hairline {
      &::before, &::after {
        height: 1px;
      }
    }
    &.thick {
      &::before, &::after {
        height: 4px;      
        
      }
    }
    &.black {
      &::before, &::after {
        height: 8px;
        margin-top: -4px;
      }
    }
    &.heavy {
      &::before, &::after {
        height: 12px;
        margin-top: -6px;
      }
    }
    &.pointy {
      &::Before, &::after {
        width: 200%;
        left: -50%;
      }
    }
    &.rounded {
      &::before, &::after {
        border-radius: 5px;
      }
    }
    &.blades {
      &::before, &::after {
        border-radius: 5px 0;
      }
    }
    &.warp {
      &::before, &::after {
        border-radius: 120% 0;
      }
    }
    &.fat {
      &::before, &::after {
        border-radius: 100%;
      }
    }
  }
  

  // Search Box
.search {
    float: right;
    padding: 0.0em 1em 0 0.5em;
    //margin-left: 64px;
    //flex-grow: 1;
    //-webkit-flex-grow: 1;
    @media (max-width: 767px) {
      //margin-left: 32px;
    }
    @media (max-width: 480px) {
     // flex-grow: initial;
     // -webkit-flex-grow: initial;
    //  margin-left: auto;
    }
  }
  .search-form {
    display: flex;
    display: -webkit-flex;
    @media (max-width: 480px) {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 8px;
      background: #fff;
      z-index: 100;
      &.active {
        display: flex;
        display: -webkit-flex;
      }
    }
    .search-keyword {
      height: 36px;
      border: 1px solid $primary-green;
      padding: 0 16px;
      border-radius: 2px 0 0 2px;
      width: 100%;
      max-width: 330px;
      &:focus {
        outline: none;
      }
      @media (max-width: 480px) {
        height: 32px;
        margin: 2px 0;
      }
    }
    .search-button {
      border-radius: 0 2px 2px 0;
      background: $primary-green
      url(../../assets/search.png)
      center no-repeat;
      width: 43px;
      @media (max-width: 480px) {
        height: 32px;
        margin: 2px 0;
      }
    }
  }
  @media (min-width: 481px) {
    .mobile-search,
    .back-button {
      display: none;
    }
  }
  @media (max-width: 480px) {
    .mobile-search {
      display: block;
      height: 36px;
      width: 36px;
    }
    .back-button {
      display: block;
      margin-right: 8px;
    }
  }
  

 