@import url("https://fonts.googleapis.com/css?family=Nunito:600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto");

/*Name: loginForm.css */


/* schon in _chekout.css enthalten 


* {
  box-sizing: border-box;
}
/*app.css überschrieben? nein .header nicht gleich header? ja 
als className="header" - wieder entnommen...
*/


/* schon in _chekout.css enthalten  */
.radio-toolbar 
 input[type="radio"] {
  display: none;
}


.radio-toolbar-selected  {
  background: none;
  border-color: green;
  color: green;
  width: calc(100% - 8px);
  border-radius: 10px;
  border: 2px solid green;
  
}

.radio-toolbar-unselected  {
  background: none;
  border-color: gray;
  color: #212529;
  width: calc(100% - 8px);
  border-radius: 10px;
}


.login {
  padding: 10px;  
  /*height: 100%;*/
  display: flex;
  justify-content: center;
  font-family: Nunito, Roboto, Arial, sans-serif;
  /*background: #eee; */
    height: 100vh;

   
}


.login-line span {
 
  display: inline-block;
  padding: 0 10px;
  position: relative;
  /*top: -10px; da Punkte leider trotz padding durchgehend sind... */
}
.login-line {
  border-top: 2px dotted #d1d1d1;
  text-align: center;
  margin: 25px;
  font-weight: 700;
      
}



.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  /*height: 70%;
  width: 45%; */
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.card .close {   /* .close aus _modal.scss*/
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 24px;
  line-height: 36px;
  border-radius: 0 4px 0 0;
  background: transparent;
  color: #666; /* $gray-medium aus _modal.scss*/
}
 .card.close:hover
 {
  background: #dddddd; /* $gray-dark-bg aus _modal.scss*/
 }

.logincontainer {
  text-align: center;
  /*color: #fff; 
  margin-top: 100px;*/  /*margin-top: 100px; Js 24.02.20*/
  padding: 20px;
  margin: 20px;
}
.logincontainer > header {
  font-size: 1.3rem;
  font-weight: 900;
  margin: 0 auto 60px auto;
  position: relative;
  height: 25px;
   /*width: 250px; */
  overflow: hidden;
}

.header-headings {
  color: green;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.header-headings > span {
  margin: 0px 0;
}
.header-headings.sign-in {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.header-headings.sign-up {
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}
.header-headings.forgot {
  -webkit-transform: translateY(-90px);
          transform: translateY(-90px);
}
.header-headings.gast {
  -webkit-transform: translateY(-60px);
          transform: translateY(-60px);
}



.account-form {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.account-form-fields {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.account-form-fields.sign-in {
  max-height: 140px;
}
.account-form-fields.sign-up {
  max-height: 250px;
}
.account-form-fields.forgot {
  max-height: 70px;
}
.account-form-fields.gast {
  max-height: 250px;
}
.account-form-fields > input {
  border: 0;
  margin-bottom: 10px;
  padding: 15px;
  font-size: 1rem;
  font-family: Nunito, sans-serif;
  color: #000;
  border-radius: 5px;
}
.account-form-fields > input::-webkit-input-placeholder {
  color: #aaa;
}
.account-form-fields > input:-ms-input-placeholder {
  color: #aaa;
}
.account-form-fields > input::-ms-input-placeholder {
  color: #aaa;
}
.account-form-fields > input::placeholder {
  color: #aaa;
}
.account-form-fields > input::-webkit-input-placeholder {
  color: #aaa;
}
.account-form-fields > input:-ms-input-placeholder {
  color: #aaa;
}
.account-form-fields > input:focus {
  outline: none;
}


footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 30px 0;
  text-align: center;
}
footer > a {
  color: #fff;
  font-weight: 700;
}


/* nicht aus ISTFramework - HWIO */
/* Fehler: zweizeilig */
.buttonGoogle {
  background: white;
	display: inline-block;
  max-width: 162px;
  min-width: 162px;/* verhindert Zweizeiligkeit.*/
	margin-top: 0px;
	border: 0;
	padding: 0 10px;
	text-align: left;
	width: 100%;
	height: 37px;
	border-radius: 4px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
 /*	-moz-font-feature-settings: "liga" on; ist standartmässig an- 
    https://developer.mozilla.org/de/docs/Web/CSS/font-feature-settings */
	color: rgba(0, 0, 0, 0.84) !important;
	fill: rgba(0, 0, 0, 0.84) !important;
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 1px 1px 1px grey;
	font: inherit;
  outline: none;  
}

.buttonGoogle .svgIcon {
	vertical-align: middle;
	fill: rgba(0, 0, 0, 0.54);
  padding-right: 0px;
  padding-left: 0px;
	height: 37px;
	display: inline-block;
}
.buttonGoogle-label{
  padding: 0px 0px 0px 0px;
  display: inline-block;
  vertical-align: middle;
  padding-top: 3px;
  padding-right: 0px;
  font-size: 14px;
  font-weight: normal;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: 'Roboto', sans-serif;
}

/* von Website Google */
@import url("https://fonts.googleapis.com/css?family=Roboto");

.customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: auto;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}
.customBtn:hover {
  cursor: pointer;
}
span.label {
  font-family: serif;
  font-weight: normal;
}
span.icon {  /*unused - aber png laden funktioniert unter css.....*/ 
  background: url('../LoginForm/btn_google_signin_light_normal_web.png') transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: 'Roboto', sans-serif;
}


.spinner {
  animation: spinner infinite .9s linear;
  height: 90%;
}

.spinner:focus{
  border:none;
}


@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.form-error{
  color: #F42B4B;
  text-align: center;
}

.form-success{
  color: rgb(13, 163, 13);
  text-align: center;
}

.form-warning{
  color: rgb(226, 176, 7);
  text-align: center;
}

.displayIcon-wrapper {
  position: relative;
  // display: flex;
  // margin-bottom: 14px; 
  i {
    position: absolute;
    top: 50%;
    right: 8%;
  }
  i:hover {
    color: #28a745;
    cursor: pointer;
  }
  
}

