 
/*----- cart -----*/
 
// Cart
.cart {
    height: 65px;   // 18.05.20 Workaround Smily: hinzugefügt  OP: Eigener Aldi Warenkorp, Höhe anpassen (auto)
    float: right;
    padding: 15px; // 02.06.20 raus: 0.5em 0.3em 0 0.5em;
    // display: flex;
    // display: -webkit-flex;
    //margin-left: 64px;

    
    z-index: 99;
    //position: relative; JS - geändert 27.07.20
    position: absolute;
    display: block;
    top: 18px;
    right: -10px;

    @media (max-width: 991px) {
      // margin-left: 16px;
    }
  }
.cart-icon {
    // margin-left: 16px;
    z-index: 99;
    position: relative;
  }
  .cart-count {
    // @media (min-width: 992px) { //js. 31.07.2019
    //  display: none;
    //}
    // @media (max-width: 991px) {
      display: block;
      position: absolute;
      background: $primary-orange;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-size: 12px;
      color: #fff;
      width: 24px;
      border-radius: 50%;
      top: -6px;
      right: -9px;
    //}
  }
  .cart-preview {
    display: none; //block; // js none;
    background: $white;
   ;
    border: 1px solid $gray-eighty;
    &:before {
      content: "";
      position: absolute;
      display: block;
      top: -9px;
      right: 8px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid $gray-eighty;
      z-index: 1;
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      top: -7px;
      right: 9px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $white;
      z-index: 1;
    }
  }  

  .cart-preview.active {

   
    z-index: 99;
    display: block;
    position: absolute;

    top: 85px; // js 48px;
    right: 0px;
    width: 360px;
    //height: 300px;  -- OP: gibt Probleme beim Scrolling...
    
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    @media (max-width: 960px) {
        top: 85px; 
      }  
    
    @media (max-width: 480px) {
        top: 85px; // js 15px;
      }
     
    @media (max-width: 400px) {
      top: 80px; // js 15px;
      width: 100%;
      min-width: 300px;
      & > div {
        width: 100% !important;
      }
      .cart-items {
        width: 100%;
      }
      .stepper-input {
        a {
          display: none;
        }
      }
    }
    
  }
  
  .action-block {
    background: $white;
    position: absolute;
    bottom: 0;
    padding: 16px;
    width: 100%;
    button {
      background: $primary-orange;
      display: block;
      width: 100%;
      &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
  .empty-cart {
    text-align: center;
    img {
      width: 100%;    
      max-width: 250px;
      margin: 42px auto 16px auto;
    }
    h2 {
      color: $gray-light;
      font-size: 18px;
    }
  }
  .cart-items {
    height: 320px;
    width: 300px; // js 360px;
  }
  .cart-item {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    padding: 8px;
    .product-image {
      width: 48px;  // 48.48
      height: 48px;
    }
    .product-info {
      margin-left: 16px;
      flex-grow: 1;
      .product-name {
        color: $gray-light;
        font-size: 14px;
      }
      .product-price {
        color: $gray-light;
        font-weight: 700;
        // &:before {
        //  content: "Preis ";
        // }
      }
    }
    .product-total {
      margin-left: 16px;
      .quantity {
        color: $gray-light;
        font-size: 14px;
        text-align: right;
      }
      .amount {
        color: $gray-dark;
        font-weight: 700;
        text-align: right;
        &:before {
          content: "Preis ";
        }
      }
    }
    .product-remove {
      margin-left: 24px;
      //  height: 24px; --js: entnommen
      line-height: 24px;
      //width: 24px;  -- js
      font-size: 22px;
      color: $gray-eighty;
      text-align: center;
      &:hover {
        color: $red;
      }
    }
    &:hover {
      background: $green-light-bg;
    }
  }
  
@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }

  10%,
  20% {
    transform: scale3d(0.8, 0.66, 0.66) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

.tada {
  animation-name: tada;
  animation-duration: 300ms;
  animation-fill-mode: both;
}
