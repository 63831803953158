.loading {
    padding: 8rem;
    text-align: center;
}

.loading-logo {
    animation: logo-spin infinite 5s linear;
    height: 10rem;
}

@keyframes logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
