.Upload {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
  .Title {
    //margin-bottom: 32px;
    color: #555;
  }
  .Content {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
    .Files {
      margin-left: 32px;
      align-items: flex-start;
      justify-items: flex-start;
      flex: 1;
      overflow-y: auto;
        
      .Filename {
         margin-bottom: 8px;
         font-size: 16px;
         color: #555;
      }      
      .Row {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        height: 65px;
        padding: 8px;
        overflow: hidden;
        box-sizing: border-box;
      }
    }
  } 
  .Actions {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 32px;
    font-size: 12px;
    
    buttonX {
     font-family: "Roboto medium", sans-serif;
     font-size: 14px;
     display: inline-block;
     height: 36px;
     min-width: 88px;
      padding: 6px 16px;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
     -ms-touch-action: manipulation;
      touch-action: manipulation;
     cursor: pointer;
     -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 0;
      border-radius: 2px;
      background: #077915;
      color: #fff;
      outline: 0;
      } 
     buttonX:disabled {
      background: rgb(189, 189, 189);
     cursor: default;
   }
  }
}

.ProgressWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  .ProgressBar {
    width: 100%;
    height: 8px;
    background-color:#077915;
    border-radius: 5px;
  }  
  .Progress {
    background-color: #077915;
    height: 100%;
    margin: 0;
    border-radius: 5px;
  }
  .CheckIcon {
    opacity: 0.5;
    margin-left: 32px;
  } 

}

.Dropzone {
  height: 250px;
  width: 250px;
  background-color: #fff;
  border: 2px dashed #0a82f3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  .Highlight {
    background-color: rgb(188, 185, 236);
  }
  
  .Icon {
   
    opacity: 0.6;
    height: 64px;
    width: 64px;
  }
  
  .FileInput {
    display: none;
  }
  
}
