
#fa-user   {
  
	font-size:35px;
	-webkit-transition: .5s all ease;
	-moz-transition: .5s all ease;
	transition: .5s all ease;
}
#fa-user:hover {
	font-size:50px;
	
}
