@import 'base/index';
@import 'header/loginBar';
@import 'loginForm';
@import 'footer';
@import 'components/index';
@import 'header/warenKorb';
/*
Todo: in base/_index.scss  - ist reset entnommen worden  - restliche Anpassungen - auch an Bootstrap fehlen noch!
Hintergrund: _reset.scss ist zu individuell - löst Komplikationen wie Fehler beim Twitter button aus...


Quellen: 
https://gridbyexample.com/patterns/header-twocol-footer-responsive/
Override Bootsstap:
https://stackoverflow.com/questions/28678542/how-to-change-bootstraps-global-default-font-size
*/

/*----- General Formating -overide Bootstrap ----- Wichtige Anleitung:*/
// https://facebook.github.io/create-react-app/docs/adding-bootstrap
// zuerst die Änderungen dann Bootstrap mit ~ Zeichen als scss @import '~'!

// Demo override bootstrap - 2 Zeilen:
$blue: #007bff;  //    #00e1ff;

//$body-bg: #000;  --> Wieder raus JS 08.06.20: 
 
//@import '~bootstrap/scss/bootstrap.scss';  // 17.07.19 lt. package.json Version "bootstrap": "^4.3.1", ersetzt CDN in index.html.
// OP: Workaround 25.11.20 obrige Zeile in App.jsx gesetzt, da btn color im loginFom.jsx den Radio Btn überschrieben hat..

// Optionale Installation von "yarn add React-Bootstrap Bootstrap" - für komplette theming falls gewünscht...
// https://blog.logrocket.com/how-to-use-bootstrap-with-react-a354715d1121/


body, html {
  font-family: Verdana, Arial, sans-serif;
  font-size: 0.95em;   // js: 10px
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;    // JS:06.04.20
}

.sidebar {
  grid-area: sidebar;
}

.sidebar2 {
  grid-area: sidebar2;
}

.content {
  grid-area: content;
}

.wrapper {
  background-color: #fff;
  color: #444;
}

.wrapper {
  min-height: 100px; // JS 01.12.20 - als Test
  padding-bottom: 5px;  // Js 02.07.20
  display: grid;
  grid-gap: 1em;
  grid-template-areas:
 
   "sidebar"
   "content"
   "sidebar2"
   
}
/* OP: als Test rausgenommen: 24.11.20
@media only screen and (min-width: 480px)  {
.wrapper {

  grid-template-columns: 20% auto;
  grid-template-areas:
 
  "sidebar  content"
  "sidebar2 sidebar2"
  
}
}

@media only screen and (min-width: 768px)   {
  .wrapper {
    grid-gap: 20px;
    grid-template-columns: 120px auto 120px;
    grid-template-areas:
   
    "sidebar content sidebar2"
   
  }
}
*/
.box {

background-color: #444;
color: #fff;
border-radius: 5px;
padding: 5px;
/*font-size: 150%; // OP: Problematisch!!!! löst viele Probleme aus */
}

.sidebar, .sidebar2 {

border: 1px solid rgba(0, 0, 0, 0.125);
background-color: white;
color: #444;

}
a:active, a:focus {outline: none;}
 
main {
  margin: 3em 1em;
  line-height: 180%;
}
