@import '../base/colors';  // JS 13.08.20 - implement "nicht bootstrap colors"
.modali-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .4;
}

.modali-wrapper {
  display: block; /* JS: 30.01.20 inline-table; - kein Scrolling bei grossen Texten!*/
  position: fixed;
  padding: .5rem;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modali {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;   // OP: 300px? https://die-netzialisten.de/em-und-rem-was-ist-der-unterschied/
  border-radius: 3px;     // 27.07.20 Nein! Problem individuell über _quickViewProduct.scss gelöst
  pointer-events: none;
}

.modali-content {
  pointer-events: auto;
}

.modali-header {
  display: flex;
  align-items: flex-start;
  padding: 1.5rem 1.5rem .5rem 1.5rem;
}

.modali-header .modali-title {
  font-weight: 700;
  font-size: 1.2rem;
}

.modali-close-button {
  padding: 1rem;
  background: white !important;
  margin: -1rem -1rem -1rem auto;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: .3;
  cursor: pointer;
  border: none;
  outline: 0 !important;
}

.modali-close-button:hover {
  opacity: .8;
  text-decoration: none;
}

.modali-body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  // background-color: #fff;
  border-radius: .3rem;
}

.modali-body-style {
  padding: .5rem 1.5rem;
}

.modali-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 1025px) {
  .modali-open {
    padding-right: 15px;
  }
}

.modali-open {
  overflow: hidden;
}

/* Size Classes */

.modali-size-regular {
  min-width: 100%;
 max-width: 500px;  
}

@media (min-width: 500px) {
  .modali-size-regular {
    min-width: 500px !important;
  }
}

/* JS - 12.02.2020* Dialog auf ganze Breite für AGB und Widerruf...*/ 
.modali-size-wide {
  min-width: 100%;   
}

@media (min-width: 500px) {
  .modali-size-wide {
    min-width: 500px !important;
  }
}


.modali-size-large {
  min-width: 100%;
  max-width: 800px;
}

@media (min-width: 500px) {
  .modali-size-large {
    min-width: 800px !important;
  }
}

/* Position Classes */

.modali-wrapper-centered {
  top: 40% !important;
}

@media (min-width: 1000px) {
  .modali-wrapper-centered {
    top: 0 !important;
    display: flex !important;
    align-items: center;
  }
}

/* Button Classes */

.modali-button {
  font-size: .9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: .3rem 1rem;
  margin-left: .5rem;
}

.modali-button-cancel {
  background: #fff;
  color: #000;
}

.modali-button-destructive {
  background: #FF1654;
  color: #fff;
}

.modali-button-default {
  background: #247BA0;
  color: #fff;
}

/* Animation Classes */

.modali-animated {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.modali-animation-fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.linkbutton
{
    padding:0;
    background-color:white !important;
    
    border:none;
    border:0;
    color: #007bff;
    cursor:pointer;
    font-family:inherit;
    font-size:inherit;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    &:hover {
      text-decoration:underline;
      color: #0056b3;
    }
}

