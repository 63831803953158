
@import '../base/colors';
//@import '~bootstrap/scss/bootstrap.scss';  // 29.07.19: für das eleminieren vom schwarzen Hintergrund - warum hier nochmal???? 

// Test - hat nicht funktioniert...
// .BootstrapDiv {
//  @import '~bootstrap/scss/bootstrap.scss';   // funktioniert nicht..
/*
OP Test - erl: Button outside with bootstap scss "product-action" + " btn btn-primary" but "not loaded ?" bootstrap.scss- 
First load shopping cart, second checkout - is bootstap active? - YES!!!  04.08.19 -js Summery: if bootstrap import is included bootstap is loaded...
Quellen: 
https://gridbyexample.com/patterns/header-twocol-footer-responsive/

*/


// @import '~bootstrap/scss/bootstrap.scss'; --- geht nicht überschreibt auch higher NICHT-bootstrapp.scss 

// _Checkout.scss
.radio-toolbar 
 input[type="radio"] {
  display: none;
}


.radio-toolbar-selected  {
  background: none;
  border-color: green;
  color: green;
  width: calc(100% - 8px);
  border-radius: 10px;
  border: 2px solid green;
  
}

.radio-toolbar-unselected  {
  background: none;
  border-color: gray;
  color: #212529;
  width: calc(100% - 8px);
  border-radius: 10px;
}
 
.sidebarGrid {
  grid-area: sidebarGrid;
}


.sidebar2Grid {
  grid-area: sidebar2Grid;
}


.contentGrid {
  grid-area: contentGrid;
}

.headerGrid {
  grid-area: headerGrid;
}

.footerGrid {
  grid-area: footerGrid;
 
}
.backGridBtn {
  position: absolute;
  bottom: 4px;
  left: 4px;
  font-size: 24px;
  line-height: 36px;
  border-radius: 0 4px 0 0;
  background: transparent;
  color: $gray-medium;
  &:hover {
    background: $gray-dark-bg;
  }
}
.fowardGridBtn {
  position: absolute;
  bottom: 4px;
  right: 4px;
  font-size: 24px;
  line-height: 36px;
  border-radius: 0 4px 0 0;
  background: transparent;
  color: $gray-medium;
  &:hover {
    background: $gray-dark-bg;
  }
}
.contentGrid
{
  /*min-height: 5000px;*/
}
.wrapperGrid {
  background-color: #fff;
  color: #444;
}

.wrapperGrid {
  display: grid;
  grid-gap: 1em;
  grid-template-areas:
   "headerGrid"
   "sidebarGrid"
   "contentGrid" 
   "sidebar2Grid"  
   "footerGrid"
}

.stepName {
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  padding: 2px;
    text-align: center;
}

@media only screen and (min-width: 399px)  {  // geändert Js: Original 500px
.wrapperGrid {
  grid-template-columns: 25% auto;
  grid-template-areas:
  "headerGrid   headerGrid"
  "sidebarGrid  contentGrid"
  "sidebar2Grid sidebar2Grid"
  "footerGrid   footerGrid";
}
.login-box { 
  min-height: 550px;   // nur  height - absoluter Fehler!!!!!
}


}

@media only screen and (min-width: 600px)   {
  .wrapperGrid {
    grid-gap: 10px;
    grid-template-columns: 100px auto 80px;
    grid-template-areas:
    "headerGrid  headerGrid  headerGrid"
    "sidebarGrid contentGrid sidebar2Grid"
    "footerGrid  footerGrid  footerGrid";
    max-width: auto; // js: 600px;
    // overflow: hidden;     wieder raus 14.04.20 wegen AGB Scrolling
  }

  .login-box { 
    min-height: 1050px;   // nur  height - absoluter Fehler!!!!!
  }
  
}

.boxGrid {
//background-color: #999; // js #444;
background-color: #eee;
color: #a1a1a1;

border-radius: 5px;
padding: 2px;
font-size: 150%; 
}

.headerGrid,
.footerGrid {
background-color: #eee; //#999;
color:#6bada7
}

.sidebar2Grid {
background-color:  #999;
color: rgb(172, 27, 27);
}
a:active, a:focus {outline: none;}
 

/*------------------------------*/
.stepperClose {
  position: relative;
  top: 0px;
  //left: 5px;
  font-size: 24px;
  line-height: 36px;
  border-radius: 0 4px 0 0;
  background: transparent;
  color: $primary-orange;
  &:hover {
    background: $gray-dark-bg;
  }
}
/*-------read only input Bestellung -----------------------*/
.form-control-xs {
  height: calc(1em + .375rem + 2px) !important;
  padding: .125rem .25rem !important;
  font-size: .75rem !important;
  line-height: 1.5;
  border-radius: .2rem;
}

/* Warenkorb ---------------------------*/


.warenkorb {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  //border: 3px solid #000;
  width: 100%;

  .cart-item_we {    
    padding: 8px;
    .product-image {
      width: 48px;  // 48.48
      height: 48px;
    }
    .product-info {
      margin-left: 16px;
      flex-grow: 1;
      .product-name {
        color: $gray-light;
        font-size: 14px;
      }
      .product-price {
        color: $gray-light;
        font-weight: 700;
        // &:before {
        //  content: "Preis ";
        // }
      }
    }    
    &:hover {
      background: $green-light-bg;
    }
  }
}

.warenkorb td, .warenkorb th {
  border: 1px solid #ddd;
  padding: 8px;
}

.warenkorb tr:nth-child(even){background-color: #f2f2f2;}

.warenkorb tr:hover {background-color: #ddd;}

.warenkorb th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  //background-color: #4CAF50;
  //color: white;
}

 /* Agb Bestellung ---------------------*/
 .form-check-input-override {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
  width: 20px;
  height: 20px;
 }

 
 /* Agb Bestellung - unnötig. ---------------------*/
 .checkout-override {
 /* min-height: 1000px;*/
  
 }
