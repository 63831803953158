@import '../base/colors';

.fasGear{
  cursor: pointer;
  color: #38d9a9;
  margin: 0;
  padding-left: .5rem;
    padding-right: .5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transform: translateZ(0);
    backface-visibility: hidden;
}


.cardUpload {
  background-color: white;
  padding: 16px;
  //width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  font-size: calc(8px + 2vmin);
  .col {    // original .col  without min-width - 
    
    min-width: unset;   // verursacht Stauchung, statt Spaltenumbruch.
   
}
  img {     
    cursor: zoom-in;
  }
  .Actions {
    display: flex;
    flex: 1;
    width: 100%;
    //align-items: flex-end;
    flex-direction: column;
    margin-top: 16px;
    font-size: calc(8px + 1vmin);  // OP: was ist vmin genau?
  }
  .Title {
    font-weight: 900;    
  }
}

.product {  
  background: $white;
  //margin: 16px; js: 01.12.2020
  //width: 400px;
  border-radius: 2px;
  // 
  div.rahmen-7 {
    position: relative
  }

  div.rahmen-7 img {
    padding: 0.5em;
    //background: #cccccc  - raus: 23.10.20 - stört Vollbildmodus image-galerie 
  }

  div.rahmen-7 span {
    position: absolute;
    bottom: 1.5em;
    left: 1.5em;
    color: #ffffff
  }

  .product-image {
    overflow: hidden;
    border-radius: 2px 2px 0 0;
    //max-height: 200px;
   
    img {     
      cursor: zoom-in;
      width: 100%;
      height: auto;
      transition: transform 300ms ease-in;
      transform: scale(1);
      @media (min-width: 991px) {
       // min-height: 200px;   -- löst Kolision mit Image Gallerie aus
      }
      &:hover {
        transform: scale(1.1);
      }   
      
    }
    
    .image-galerie  //Developer: JS - Name in deutsch  OP: Test Media mit und ohne Werbegrid - wenn DoubleClick enthalten ist 
    {
      //min-width: 320px; 
      min-height: 150px;   // ist nicht media abhängig und funktioniert nicht so wie gedacht
      border-Style: groove;
      border-Color: gold; 
      border-Width: 10px; 
    
    }
    .image-galerieXXX  //Developer: JS - Name in deutsch  OP: Test Media mit und ohne Werbegrid - wenn DoubleClick enthalten ist 
    {
      //min-width: 800px; --- muss weg! 23.10.20 -JS
      
      @media (min-width: 991px) {
        min-width: 800px;
        min-height: 800px;
      }
      @media (min-width: 768px) {
        min-width: 750px;
        min-height: 750px;
      }
      @media (min-width: 480px) {
        min-width: 450px;
        min-height: 450px;
      }
      @media (min-width: 320px) {
        min-width: 280px;
        min-height: 280px;
      }
    }
  }
  .product-name {
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;  //js
    margin-bottom: 8px;
  }
  .product-text {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .product-price {
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 16px;
    &:before {
      content: "Preis: ";
    }
    &:after {
      content: "";
    }
  }
  .product-name,
  .product-text,
  .product-price {
    margin-top: 10px;
    color: $gray-medium;
    padding: 0 16px;
    text-align: center;
  }
  .product-action {
    padding: 16px;
    button {
      width: 100%;
      transition: all 300ms ease-in;
      &.added {
        background: $primary-orange;
      }
    }
  }
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

